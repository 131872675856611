<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Controller, Navigation } from 'swiper';
import axios from 'axios';
import { assetURL, baseURL } from '../utils/url';
import { sanitizeUrl } from '@braintree/sanitize-url';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    data() {
        return {
            isTablet: false,
            socialLinks: [
                {
                    text: '',
                    icon: `${assetURL}images/icons/facebook`,
                    link: '#',
                    title: 'Facebook Local',
                },
                {
                    text: '',
                    icon: `${assetURL}images/icons/instagram`,
                    link: '#',
                    title: 'Instagram',
                },
                // {
                //     text: '',
                //     icon: `${assetURL}images/icons/twitter`,
                //     link: '#',
                //     title: 'Twitter',
                // },
                {
                    text: '',
                    icon: `${assetURL}images/icons/youtube`,
                    link: '#',
                    title: 'Youtube',
                },
            ],
            mouseImage: `${assetURL}images/mouse.png`,
            mouseImageWebp: `${assetURL}images/mouse.webp`,
            contact: {
                icon: `${assetURL}images/icons/phone`,
                text: '+63 917 678 4427',
                link: '#',
            },
            reservationLink: '/contact-us/inquiry',
            slides: [],
            activeSlideIndex: 0,
            mobileVersion: false,
            navButtons: {
                prevEl: '.slidePrev-btn',
                nextEl: '.slideNext-btn',
            },
            breakpoints: {
                1: {
                    direction: 'vertical',
                    mousewheel: true,
                    loop: false,
                },
                768: {
                    direction: 'horizontal',
                    loop: false,
                },
            },
        };
    },
    methods: {
        sanitize(url) {
            return sanitizeUrl(url);
        },
        getSlides() {
            axios.get('/api/home/carousel/').then((response) => {
                this.$data.slides = response.data.slides;
            });
        },
        getLinks() {
            axios.get('/api/home/links/').then((response) => {
                this.socialLinks.forEach((value, index) => {
                    response.data.footer.forEach((value2, index2) => {
                        if (value.title == value2.text) {
                            value.link = value2.url;
                        }
                    });
                });
            });
        },
        changeSwiperIndex(swiper) {
            this.$data.mobileVersion =
                document.getElementsByClassName('swiper-vertical').length != 0;
            this.activeSlideIndex = ref(swiper.activeIndex);
            if (swiper.activeIndex == 0) {
                return this.animationInit();
            }
            if (swiper.previousIndex > this.activeSlideIndex) {
                return this.slideTo('prev', swiper);
            }
            return this.slideTo('next', swiper);
        },
        changeSlide(index) {
            if (index == this.activeSlideIndex) {
                return;
            }
            if (this.activeSlideIndex > index) {
                if (this.activeSlideIndex == 0) {
                    return;
                }
                return document.getElementById('prev-btn').click();
            }
            return document.getElementById('next-btn').click();
        },
        slideTo(direction, swiper) {
            if (swiper.activeSlideIndex == swiper.previousIndex) {
                return;
            }
            if (swiper.activeSlideIndex == 0) {
                return;
            }
            if (this.activeSlideIndex == this.$data.slides.length - 1) {
                return;
            }

            const slider = document.getElementById('thumbnail-slider');
            const sliderSize = this.$data.mobileVersion
                ? slider.offsetHeight
                : slider.offsetWidth;
            const wrapper = slider.querySelector('.swiper-wrapper');
            const offsetTranslate = this.$data.mobileVersion
                ? wrapper.style.transform.substring(17).split(',')[0]
                : wrapper.style.transform.substring(12).split(',')[0];
            const slidesize = sliderSize / 2;

            if (direction == 'next') {
                if (!this.$data.mobileVersion) {
                    const newX =
                        parseInt(offsetTranslate.split('px')[0]) - slidesize;
                    wrapper.style.transitionDuration = '1000ms';
                    wrapper.style.transform = `translate3d(${newX}px, 0px, 0px)`;
                    setTimeout(() => {
                        wrapper.style.transitionDuration = '0ms';
                    }, 1000);
                    return;
                }
                const newY =
                    parseInt(offsetTranslate.split('px')[0]) - slidesize;
                wrapper.style.transitionDuration = '1000ms';
                wrapper.style.transform = `translate3d(0px, ${newY}px, 0px)`;
                setTimeout(() => {
                    wrapper.style.transitionDuration = '0ms';
                }, 1000);
                return;
            }
            if (!this.$data.mobileVersion) {
                const newX =
                    parseInt(offsetTranslate.split('px')[0]) + slidesize;
                wrapper.style.transitionDuration = '1000ms';
                wrapper.style.transform = `translate3d(${newX}px, 0px, 0px)`;
                setTimeout(() => {
                    wrapper.style.transitionDuration = '0ms';
                }, 1000);
                return;
            }
            const newY = parseInt(offsetTranslate.split('px')[0]) + slidesize;
            wrapper.style.transitionDuration = '1000ms';
            wrapper.style.transform = `translate3d(0px, ${newY}px, 0px)`;
            setTimeout(() => {
                wrapper.style.transitionDuration = '0ms';
            }, 1000);
        },
        animationInit() {
            const wrapper = document
                .getElementById('thumbnail-slider')
                .querySelector('.swiper-wrapper');
            wrapper.style.transitionDuration = '0';
            wrapper.style.transform = 'translate3d(0px, 0px, 0px)';
        },
        checkIfDesktop() {
            const screenWidth = ref(window.innerWidth);
            if (screenWidth.value >= 768) {
                this.isTablet = true;
            }
        },
    },
    mounted() {
        AOS.init();
        this.getSlides();
        this.animationInit();
        this.getLinks();
        this.$data.mobileVersion =
            document.getElementsByClassName('swiper-vertical').length != 0;
        this.checkIfDesktop();
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const firstSwiper = ref(null);
        const secondSwiper = ref(null);
        const setFirstSwiper = (swiper) => {
            firstSwiper.value = swiper;
        };
        const setSecondSwiper = (swiper) => {
            secondSwiper.value = swiper;
        };

        return {
            modules: [Controller, Navigation],
            firstSwiper,
            secondSwiper,
            setFirstSwiper,
            setSecondSwiper,
        };
    },
};
</script>
<template>
    <div class="tw-relative tw-font-albert-sans">
        <div
            class="tw-absolute tw-z-10 tw-h-[70px] tw-right-24 tw-top-3 tw-hidden lg:tw-block"
        >
            <div
                class="tw-flex tw-content-center tw-items-center tw-justify-center tw-h-full tw-gap-7"
            >
                <social class="tw-flex tw-gap-2">
                    <a
                        v-for="social in socialLinks"
                        class="tw-no-underline tw-text-white hover:tw-text-white tw-text-xs tw-flex tw-h-6 tw-space-x-1"
                        :href="this.sanitize(social.link)"
                        :title="social.title"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <picture>
                            <source
                                type="image/webp"
                                :srcset="social.icon + '.webp'"
                            />
                            <img
                                :src="social.icon + '.png'"
                                alt=""
                                width=""
                                height=""
                                class="tw-h-full"
                                style="filter: invert(1)"
                            />
                        </picture>
                        <p
                            v-if="social.text != ''"
                            class="tw-my-auto tw-font-thin tw-text-xs"
                        >
                            {{ social.text }}
                        </p>
                    </a>
                </social>
                <a
                    class="tw-no-underline tw-text-white hover:tw-text-white tw-text-xs tw-flex tw-h-6 tw-space-x-2"
                    :href="this.sanitize(contact.link)"
                    :title="contact.title"
                >
                    <picture>
                        <source
                            type="image/webp"
                            :srcset="contact.icon + '.webp'"
                        />
                        <img
                            :src="contact.icon + '.png'"
                            alt=""
                            width=""
                            height=""
                            class="tw-h-[20px] tw-w-[20px] tw-mt-[2px]"
                        />
                    </picture>
                    <p class="tw-my-auto tw-font-thin tw-text-xs">
                        {{ contact.text }}
                    </p>
                </a>
                <a
                    class="tw-no-underline tw-text-white tw-text-xs tw-flex tw-h-[42px]"
                    :href="this.sanitize(reservationLink)"
                >
                    <button
                        class="tw-w-[175px] tw-bg-transparent tw-border-solid tw-border-[1px] tw-border-white tw-text-white tw-tracking-[1.5px] tw-font-semibold tw-text-xs"
                    >
                        INQUIRE NOW
                    </button>
                </a>
            </div>
        </div>

        <div
            class="homepage-banner__indicator tw-absolute tw-bottom-[0px] tw-z-10 tw-flex tw-flex-col tw-items-center tw-h-[84px] text-center tw-left-[5%] tw-bottom-[16px]"
        >
            <picture>
                <source type="image/webp" :srcset="mouseImageWebp" />
                <img :src="mouseImage" />
            </picture>
            <div class="tw-h-[20px] tw-w-[1px] tw-bg-white tw-my-[8px]"></div>
            <p class="tw-m-[0px] fw-regular fs-12px tw-text-white3 lh-1">
                SCROLL<br />DOWN
            </p>
        </div>

        <div
            class="tw-absolute tw-bottom-0 tw-right-0 md:tw-right-[30px] tw-z-10 tw-w-[80%] md:tw-w-[70%] lg:tw-w-[1040px] md:tw-h-[150px] lg:tw-h-[240px] tw-overflow-scroll md:tw-overflow-visible"
            id="thumbnail-slider"
        >
            <button
                class="p-0px slidePrev-btn tw-absolute tw-border-none tw-z-10 tw-right-[0px] tw-w-[32px] tw-h-[32px] tw-bg-white tw-text-darkGreen2 md:tw-left-[-32px] md:tw-bottom-[40%] md:tw-text-white md:tw-bg-transparent"
                id="prev-btn"
            >
                <i
                    aria-hidden="true"
                    class="fa fs-12px fw-bold"
                    :class="isTablet ? 'fa-chevron-left' : 'fa-chevron-up'"
                ></i>
            </button>
            <button
                class="p-0px slideNext-btn tw-absolute tw-border-none tw-z-10 tw-right-[0px] tw-bottom-[0px] tw-w-[32px] tw-h-[32px] tw-bg-white tw-text-darkGreen2 md:tw-right-[-32px] md:tw-bottom-[40%] md:tw-text-white md:tw-bg-transparent"
                id="next-btn"
            >
                <i
                    aria-hidden="true"
                    class="fa fs-12px fw-bold"
                    :class="isTablet ? 'fa-chevron-right' : 'fa-chevron-down'"
                ></i>
            </button>
            <swiper
                :modules="modules"
                :navigation="navButtons"
                :breakpoints="breakpoints"
                :step="1"
                :speed="1500"
                :slidesPerView="1"
                :centered-slides="true"
                :slides-per-group="1"
                :controller="{ control: firstSwiper }"
                @swiper="setSecondSwiper"
                @slideChange="changeSwiperIndex"
                :spaceBetween="0"
                :virtual-translate="true"
                :prevent-clicks-propagation="true"
                ref="carouselController"
                class="tw-h-full carouselController"
            >
                <swiper-slide v-for="(slide, index) in slides">
                    <div
                        class="tw-flex"
                        :class="[
                            index == this.$data.activeSlideIndex
                                ? 'tw-bg-darkGreenOpacity1'
                                : 'tw-bg-whiteOpacity1',
                        ]"
                        @click="changeSlide(index)"
                    >
                        <div
                            class="tw-flex tw-items-center tw-p-[8px] tw-w-auto"
                        >
                            <img
                                :src="slide.thumbnail_link"
                                class="tw-w-[104px] tw-h-[104px]"
                            />
                        </div>
                        <div class="tw-flex tw-items-center tw-px-[8px]">
                            <div class="tw-flex tw-flex-col">
                                <h2
                                    class="line-clamp line-clamp__3lines tw-font-light tw-tracking-widest mb-8px text-uppercase tw-text-[10px]"
                                    :class="[
                                        index == this.$data.activeSlideIndex
                                            ? 'tw-text-lightBrown'
                                            : 'tw-text-darkGreen',
                                    ]"
                                >
                                    {{ slide.slider_sub }}
                                </h2>
                                <h1
                                    class="homepage-slider2__heading-text line-clamp line-clamp__2lines tw-text-[16px] tw-font-bold"
                                    :class="[
                                        index == this.$data.activeSlideIndex
                                            ? 'tw-text-white'
                                            : 'tw-text-black',
                                    ]"
                                >
                                    {{ slide.slider_header }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <swiper
            :modules="modules"
            @swiper="setFirstSwiper"
            :controller="{ control: secondSwiper }"
            :speed="1500"
            :slidesPerView="1"
            :slides-per-group="1"
            class="bg-swiper tw-h-[80vh] lg:tw-h-[100vh]"
            id="banner-slider"
        >
            <swiper-slide
                v-for="(slide, index) in slides"
                :class="' swiperSlide' + index"
            >
                <div class="tw-h-[100%] tw-relative homepage-banner__slide">
                    <img
                        class="ratio ratio-9x16 homepage-banner__img tw-object-cover"
                        :src="slide.background_link"
                    />
                    <div
                        class="tw-absolute tw-top-[10%] md:tw-top-[20%] tw-left-[5%] md:tw-left-[10%] homepage-banner__description"
                    >
                        <div v-if="slide.type == 1">
                            <h2
                                v-if="index == this.$data.activeSlideIndex"
                                class="tw-text-base fs-20px-md pr-30px-lg lg:tw-text-xl tw-text-white tw-tracking-[0.5px]"
                                data-aos="fade-right"
                                data-aos-delay="200"
                                data-aos-once="true"
                            >
                                {{ slide.sub }}
                            </h2>
                            <h1
                                v-if="index == this.$data.activeSlideIndex"
                                class="slide__heading-text tw-text-4xl lg:tw-text-7xl tw-font-extralight tw-text-white tw-mb-5"
                                data-aos="fade-right"
                                data-aos-delay="400"
                                data-aos-once="true"
                            >
                                {{ slide.header }}
                            </h1>
                            <a
                                class="tw-no-underline"
                                :href="this.sanitize(slide.link)"
                            >
                                <div
                                    v-if="index == this.$data.activeSlideIndex"
                                    class="slide__linkout-text tw-text-white tw-w-56 xl:tw-w-80 tw-text-center tw-text-[12px] xl:tw-text-sm tw-tracking-[2px] tw-font-extrabold md:tw-bg-white tw-border-gray-100 tw-h-8 xl:tw-h-12 tw-flex md:tw-justify-center mt-30px-md"
                                    style="align-items: center"
                                    data-aos="fade-right"
                                    data-aos-delay="600"
                                    data-aos-once="true"
                                >
                                    {{ slide.button }}
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-chevron-right ml-12px mb-3px-xl fs-8px fs-10px-xl fw-bold"
                                    ></i>
                                </div>
                            </a>
                        </div>
                        <div v-if="slide.type == 2">
                            <div
                                v-if="index == this.$data.activeSlideIndex"
                                class="slide__heading-text tw-pr-[10%] tw-text-4xl lg:tw-text-7xl tw-font-extralight tw-text-white tw-mb-5"
                                data-aos="fade-right"
                                data-aos-delay="200"
                                data-aos-once="true"
                            >
                                <h1 class="tw-font-extralight">
                                    {{ slide.header }}
                                </h1>
                            </div>
                            <h1
                                v-if="index == this.$data.activeSlideIndex"
                                class="slide__heading-text tw-text-4xl lg:tw-text-7xl tw-font-extralight tw-text-white tw-mb-5"
                                data-aos="fade-right"
                                data-aos-delay="400"
                                data-aos-once="true"
                            >
                                {{ slide.header }}
                            </h1>

                            <a
                                class="tw-no-underline"
                                :href="this.sanitize(slide.link)"
                            >
                                <div
                                    v-if="index == this.$data.activeSlideIndex"
                                    class="slide__linkout-text-type2 tw-w-56 xl:tw-w-80 tw-text-center tw-text-[12px] xl:tw-text-sm tw-tracking-[2px] tw-font-extrabold tw-border-gray-100 tw-h-8 xl:tw-h-12 mt-30px-md"
                                    data-aos="fade-right"
                                    data-aos-delay="600"
                                    data-aos-once="true"
                                >
                                    {{ slide.button }}
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-chevron-right ml-12px mb-3px-xl fs-8px fs-10px-xl fw-bold"
                                    ></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<style lang="scss" scoped>
.swiper-slide {
    cursor: grab;
}

.carouselController {
    .swiper-slide {
        height: 120px !important;
    }

    .swiper {
        overflow: initial !important;
    }
}
#thumbnail-slider {
    width: 296px;
    height: calc(120px * 2);
}
.homepage-slider2__heading-text {
    line-height: 1.2;
}

.slide__linkout-text {
    &-type2 {
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
.line-clamp {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.line-clamp__3lines {
    -webkit-line-clamp: 3;
}
.line-clamp__2lines {
    -webkit-line-clamp: 2;
}
@media only screen and (max-width: 767px) {
    .carouselController {
        max-width: 350px;
        right: 0;
        position: absolute;
    }
}
@media only screen and (min-width: 768px) {
    .slide__heading-text {
        line-height: 1;
        font-size: 8vw;
        padding: 0 30px 0 0;
        margin: 0;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: initial;
        text-overflow: ellipsis;
        padding-right: 10% !important;
    }
    .slide__linkout-text {
        color: #125948;
        height: 50px;
    }
    .slide__linkout-text-type2 {
        border: 1px solid #ffffff;
        width: fit-content;
        padding: 0 15px;
    }
    .carouselController {
        .swiper-slide {
            width: 296px !important;
            img {
                object-fit: cover;
                object-position: center;
            }
        }

        .swiper {
            overflow: initial !important;
        }
    }
    #thumbnail-slider {
        bottom: 0;
        height: auto;
        width: calc(296px * 2);
    }
    .homepage-slider2__heading-text {
        line-height: 1;
    }
}

// @media only screen and (min-width: 992px) {
//     .carouselController {
//             width: calc(100% - 240px);
//             margin: 0 auto;
//             left: 100px;
//     }
// }

@media only screen and (min-width: 1200px) {
    .slide__heading-text {
        padding-right: 10% !important;
        font-size: 6vw;
        padding: 0;
        -webkit-line-clamp: 2;
    }
    #thumbnail-slider {
        width: calc(296px * 2);
    }
    .carouselController {
        // width: auto;
        // left: 0;
        .swiper-wrapper {
            justify-content: flex-end;
        }
    }
}

@media only screen and (min-width: 1500px) {
    .slide__heading-text {
        padding-right: 10% !important;
        height: auto;
        -webkit-line-clamp: 3;
    }
}
</style>
