<script setup>
import AOS from 'aos';
import HomeCarousel from './HomeCarousel.vue';
import About from './About.vue';
import Estate from './Estate.vue';
import Properties from './Properties.vue';
import LatestNews from './LatestNews.vue';
import QuickContactForm from './QuickContactForm.vue';
import { contactBanner } from '../constants/data';

import 'aos/dist/aos.css';
</script>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            items: [],
            isShowingPointer: true,
        };
    },
    methods: {
        getNews() {
            axios.get('/api/home/news').then((response) => {
                this.$data.items = response.data.news;
            });
        },
        pageLoad() {
            document
                .getElementById('ortigasLandPage')
                .classList.add('tw-pointer-events-none');
            document
                .getElementById('mainNavigation')
                .classList.add('tw-pointer-events-none');

            setTimeout(() => {
                document
                    .getElementById('ortigasLandPage')
                    .classList.remove('tw-pointer-events-none');
                document
                    .getElementById('mainNavigation')
                    .classList.remove('tw-pointer-events-none');
                return (this.$data.isShowingPointer = false);
            }, 3000);
        },
    },
    mounted() {
        this.getNews();
        AOS.init();
        this.pageLoad();
    },
};
</script>
<template>
    <div>
        <section class="tw-relative w-100" id="pulsingAnimation">
            <div
                id="megamenuCursorContainer"
                class="tw-z-[15] tw-bg-blackOpaque tw-absolute tw-right-0 tw-top-0 tw-w-full tw-h-[calc(100vh-168px)] lg:tw-h-full tw-hidden tw-justify-center lg:tw-items-center lg:tw-content-center"
                v-if="isShowingPointer"
                :class="[isShowingPointer ? 'lg:tw-flex' : 'tw-hidden']"
            >
                <div id="megamenuCursor" class="">
                    <div
                        id="megamenuCursorCircle"
                        class="quick-search__cursor-circle"
                    ></div>
                </div>
            </div>
        </section>
        <section>
            <HomeCarousel></HomeCarousel>
        </section>
        <section>
            <About></About>
        </section>
        <section>
            <Estate></Estate>
        </section>
        <section>
            <Properties></Properties>
        </section>
        <section class="tw-relative">
            <LatestNews
                title="Latest News"
                description="We've built traditions and developed new inspirations for living. Our master-planned estates include residential and commercial spaces, built in mind for those who want to live, work, and play with everything they want and need within reach."
                button_text="MORE STORIES"
                homepage="yes"
                :items="items"
            >
            </LatestNews>
        </section>
        <section class="homepage__inquiry-section">
            <div class="tw-w-full md:tw-w-[90%] tw-relative inquiry-section">
                <div>
                    <img
                        class="tw-w-full tw-object-cover tw-object-top md:tw-w-[80%] tw-h-[240px] md:tw-h-[480px] homepage__inquiry-banner"
                        :src="contactBanner"
                        data-aos="fade-right"
                        data-aos-easing="ease-in-sine"
                        data-aos-once="true"
                        data-aos-duration="500"
                    />
                </div>
                <!-- <div
                    class="tw-w-full md:tw-w-[80%] tw-h-[240px] md:tw-h-[480px] tw-bg-no-repeat tw-bg-cover homepage__inquiry-banner"
                    :style="{
                        'background-image': 'url(' + contactBanner + ')',
                    }"
                ></div> -->
                <div
                    class="tw-w-full md:tw-w-[60%] lg:tw-w-[720px] tw-h-auto tw-absolute md:-tw-bottom-8 tw-right-0 homepage__inquiry-form contact-form__wrapper"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-duration="500"
                >
                    <QuickContactForm></QuickContactForm>
                </div>
            </div>
        </section>
    </div>
</template>
