<script>
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    data() {
        return {
            details: [],
            columnCount: 0,
            show: null,
            hide: 'tw-opacity-0',
            executed: false,
        };
    },
    methods: {
        getDetails() {
            axios.get('/api/home/about/').then((response) => {
                this.$data.details = response.data.details;
                this.columnCount = Object.keys(response.data.details).length;
                // this.columnCount = 4;
            });
        },
        updateScroll() {
            const aboutSection = document.querySelector(
                '#homepageAboutSection'
            );

            const aboutSection_Get_Position =
                aboutSection.getBoundingClientRect();
            const aboutSection_Get_Y_Position = aboutSection_Get_Position.top;

            const scroll = document.documentElement.scrollTop;
            // console.log("scroll: "+scroll+ " y: "+aboutSection_Get_Y_Position)

            if (aboutSection_Get_Y_Position < 525) {
                if (!this.executed) {
                    this.executed = true;
                    this.show = 'active';
                    setTimeout(() => {
                        this.show = 'active done';
                    }, 800);
                    setTimeout(() => {
                        this.hide = 'tw-opacity-100';
                    }, 1000);
                }
            }
        },
    },

    mounted() {
        this.getDetails();
        AOS.init();
        window.addEventListener('scroll', this.updateScroll);
    },
};
</script>
<template>
    <div
        id="homepageAboutSection"
        class="my-80px-xl tw-p-5 xl:tw-px-28 xxl:tw-px-44 tw-space-y-6 md:tw-space-y-0 md:tw-space-x-3 xxl:tw-space-x-8 md:tw-flex"
    >
        <div
            v-for="(column, index) in details"
            :class="`tw-space-y-5 ` + `md:tw-w-1/` + this.columnCount"
            style="width: 100%"
        >
            <div
                v-for="detail in column"
                :class="
                    `details__column` +
                    detail.column +
                    ` details__index` +
                    detail.index
                "
            >
                <h1
                    v-if="detail.is_header_type"
                    class="tw-text-darkGreen tw-leading-10 tw-text-[40px] md:tw-text-3xl lg: xl:tw-text-5xl tw-font-extrabold"
                >
                    {{ detail.text }}
                </h1>
                <h2
                    v-if="!detail.is_header_type"
                    class="tw-text-black tw-text-base md:tw-text-xs lg:tw-text-base"
                >
                    {{ detail.text }}
                </h2>
                <div
                    v-if="!detail.is_default_image"
                    class="slide-background-image"
                    v-bind:class="show"
                >
                    <img
                        :src="detail.aws_image_link"
                        class="tw-w-full"
                        v-bind:class="hide"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@media (min-width: 768px) {
    .details__column3.details__index1 {
        div:first-child {
            margin-bottom: 20px;
        }
    }
    .details__column1.details__index2 {
        div:first-child {
            margin-bottom: 20px;
        }
    }
}
</style>
