<script>
import axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { assetURL, baseURL } from '../utils/url';

export default {
    data() {
        return {
            show: null,
            hide: 'tw-opacity-0',
            executed: false,
            detail: {
                starting_price: '',
                sell_rate: '',
                about_properties: '',
                banner_link: '',
                first_featured_image: '',
                second_featured_image: '',
            },
            banner: `${assetURL}images/properties/homepage-banner.webp`,
            banner1: `${assetURL}images/properties/1.webp`,
            banner2: `${assetURL}images/properties/2.webp`,
        };
    },

    methods: {
        getDetail() {
            axios.get('/getPropertyDetails').then((response) => {
                if (response.data.detail != null) {
                    this.$data.detail = response.data.detail;
                }
            });
        },
        updateScroll() {
            const propertyBanner = document.querySelector(
                '#homepagePropertyBanner'
            );

            const propertyBanner_Get_Position =
                propertyBanner.getBoundingClientRect();
            const propertyBanner_Get_Y_Position =
                propertyBanner_Get_Position.top;

            const scroll = document.documentElement.scrollTop;
            // console.log(propertyBanner_Get_Y_Position);
            if (propertyBanner_Get_Y_Position < 238) {
                if (!this.executed) {
                    this.executed = true;
                    this.show = 'active';
                    setTimeout(() => {
                        this.show = 'active done';
                    }, 800);
                    setTimeout(() => {
                        this.hide = 'tw-opacity-100';
                    }, 1000);
                }
            }
        },
    },

    mounted() {
        this.getDetail();
        AOS.init();
        window.addEventListener('scroll', this.updateScroll);
    },
};
</script>
<template>
    <div
        class="tw-h-auto tw-relative mt-60px-xl tw-mb-10"
        id="homepagePropertyBanner"
    >
        <div class="tw-relative property-banner__main" v-bind:class="show">
            <img
                class="property-banner__img"
                alt="Property Main Banner"
                :src="detail.banner_link"
            />
            <div
                data-aos="curtainOutRight"
                data-aos-once="true"
                data-aos-offset="200"
                data-aos-duration="2000"
                data-aos-delay="0"
                class="tw-h-full tw-w-full tw-left-0 tw-top-0 tw-bottom-0 tw-absolute tw-bg-white"
            ></div>
        </div>

        <div
            class="tw-relative tw-bottom-0 tw-right-0 tw-w-full 
                    xl:tw-absolute xl:tw-w-[50%] 
                    tw-z-10 property__boxes-container"
        >
            <div class="tw-flex" data-module="countup">
                <div
                    data-aos="fade-in"
                    data-aos-once="true"
                    class="property__header-description1 tw-bg-brown tw-w-1/2 tw-h-[128px] xl:tw-h-[240px] tw-flex tw-flex-row tw-items-center tw-justify-start"
                >
                    <div class="tw-px-[30px]">
                        <h1
                            v-if="detail.is_header_description_1"
                            class="tw-font-extrabold tw-text-[20px] lg:tw-leading-[3rem] md:tw-text-[1.5rem] lg:tw-text-[3rem] tw-text-white"
                        >
                            {{ detail.description_1 }}
                        </h1>
                        <h5
                            v-else
                            class="fw-regular tw-text-white2 tw-text-base tw-text-16px md:tw-text-[14px] lg:tw-text-[17px] tw-mb-0"
                        >
                            {{ detail.description_1 }}
                        </h5>
                    </div>
                </div>
                <div
                    data-aos="fade-in"
                    data-aos-delay="200"
                    data-aos-once="true"
                    class="property__header-description2 px-30px tw-bg-white tw-w-1/2 tw-h-[128px] xl:tw-h-[240px] tw-flex tw-flex-col tw-items-start tw-justify-center"
                >
                    <h1
                        v-if="detail.is_header_description_2"
                        class="tw-font-extrabold tw-text-[20px] lg:tw-leading-[3rem] md:tw-text-[1.5rem] lg:tw-text-[3rem] tw-text-black2"
                    >
                        {{ detail.description_2 }}
                    </h1>
                    <h5
                        v-else
                        class="fw-regular tw-text-darkTeal tw-text-base tw-text-16px md:tw-text-[14px] lg:tw-text-[17px] tw-mb-0"
                    >
                        {{ detail.description_2 }}
                    </h5>
                </div>
            </div>
            <div
                class="tw-bg-grey1 tw-h-auto tw-py-8 tw-px-6 
                        xl:tw-p-12 xl:tw-h-[320px]
                        tw-space-y-6"
            >
                <h1
                    class="tw-text-darkGreen tw-text-[32px] tw-leading-8 md:tw-text-[2rem] tw-font-extrabold"
                    data-aos="fade-left"
                    data-aos-once="true"
                    data-aos-delay="200"
                >
                    Properties
                </h1>
                <h2
                    class="tw-text-black tw-text-base fs-16px md:tw-text-[19px] tw-w-full xl:tw-w-[520px]"
                    data-aos="fade-left"
                    data-aos-once="true"
                    data-aos-delay="300"
                >
                    {{ detail.about_properties }}
                </h2>
                <a class="xl:tw-absolute xl:tw-bottom-[25px]" href="/properties">
                    <button
                        data-aos="fade-left"
                        data-aos-once="true"
                        data-aos-delay="400"
                        class="fw-semibold tw-bg-darkGreen2 tw-border-none tw-h-[48px] tw-w-[214px] tw-text-white tw-text-[14px] tw-tracking-[2px] mt-60px mt-46px-lg mt-15px-xl"
                    >
                        BROWSE PROPERTIES
                    </button>
                </a>
            </div>
        </div>
        <div
            class="property-banner__sub tw-relative xl:tw-absolute tw-bottom-0 tw-left-0 tw-w-full 
                    xl:tw-w-[50%]"
        >
            <div
                class="tw-flex tw-flex-col xl:tw-flex-row tw-overflow-hidden tw-h-[100%]"
            >
                <div
                    class="slide-background-image tw-w-full tw-bg-no-repeat tw-bg-cover tw-bg-center tw-relative tw-z-20 tw-overflow-hidden"
                    v-bind:class="show"
                >
                    <!-- :style="`background-image: url(`+banner1+`);`" -->
                    <img
                        :src="detail.first_featured_image"
                        v-bind:class="hide"
                        class="w-100 tw-h-[100%] tw-object-cover xl:tw-object-cover"
                    />
                </div>
                <!-- <div data-aos="curtainOutRight" data-aos-once="true" data-aos-duration="2000" data-aos-delay="800" class="aos-transform-100 tw-h-full tw-w-full tw-left-0 tw-top-0 tw-bottom-0 tw-absolute tw-bg-midGreen"></div> -->
                <div
                    class="slide-background-image tw-w-full tw-bg-no-repeat tw-bg-cover tw-bg-center tw-relative tw-z-10 tw-overflow-hidden"
                    v-bind:class="show"
                >
                    <img
                        :src="detail.second_featured_image"
                        v-bind:class="hide"
                        class="w-100 tw-h-[100%] tw-object-cover xl:tw-object-cover"
                    />
                </div>
                <!-- <div data-aos="curtainOutRight" data-aos-once="true" data-aos-duration="2000" data-aos-delay="1200" class="tw-z-20 tw-h-full tw-w-full tw-left-0 tw-top-0 tw-bottom-0 tw-absolute tw-bg-midGreen"></div> -->
            </div>
        </div>
    </div>
</template>
