<script>
import axios from 'axios';
import { sanitizeUrl } from '@braintree/sanitize-url';

import AOS from 'aos';
import { assetURL } from '../utils/url';
import 'aos/dist/aos.css';

export default {
    data() {
        return {
            estates: [],
            banner: `${assetURL}images/estate-banner.png`,
            show: null,
            hide: 'tw-opacity-0',
            executed: false,
        };
    },

    methods: {
        sanitize(url) {
            return sanitizeUrl(url);
        },
        getEstates() {
            axios.get('/api/home/estates/').then((response) => {
                this.$data.estates = response.data.estates;
            });
        },
        updateScroll() {
            const estateSection = document.querySelector(
                '#homepageEstateSection'
            );

            const estateSection_Get_Position =
                estateSection.getBoundingClientRect();
            const estateSection_Get_Y_Position = estateSection_Get_Position.top;

            const scroll = document.documentElement.scrollTop;
            if (estateSection_Get_Y_Position < 238) {
                if (!this.executed) {
                    this.executed = true;
                    this.show = 'active';
                    setTimeout(() => {
                        this.show = 'active done';
                        this.hide = 'tw-opacity-100';
                    }, 600);
                }
            }
        },
    },

    mounted() {
        this.getEstates();
        AOS.init();
        window.addEventListener('scroll', this.updateScroll);
    },
};
</script>
<template>
    <div
        class="tw-relative tw-h-[90vh] xl:tw-h-[100vh] tw-mb-[200px] lg:tw-mb-[240px] xl:tw-mb-[200px] xxl:tw-mb-0"
        id="homepageEstateSection"
    >
        <div class="tw-grid lg:tw-grid-cols-2 lg:tw-gap-2">
            <div class="order-1 order-lg-2 tw-text-center lg:tw-text-left">
                <div class="tw-space-y-6 tw-mb-10 tw-p-5">
                    <h2
                        class="tw-text-darkGreen tw-text-5xl md:tw-text-[2rem] tw-font-extrabold fs-48px-xl"
                        data-aos="fade-left"
                        data-aos-once="true"
                        data-aos-delay="200"
                    >
                        Estates
                    </h2>
                    <h1
                        class="tw-text-black tw-text-base md:tw-text-[17px] tw-w-full xxl:tw-w-[700px] fs-16px-xl"
                        data-aos="fade-left"
                        data-aos-once="true"
                        data-aos-delay="300"
                    >
                        We've built traditions and developed new inspirations
                        for living. Our master-planned estates include
                        residential and commercial spaces, built in mind for
                        those who want to live, work, and play with everything
                        they want and need within reach.
                    </h1>
                    <a href="/estates">
                        <button
                            class="tw-mt-[30px] tw-bg-darkGreen2 tw-border-none tw-h-[48px] tw-w-[214px] tw-text-white tw-text-[14px] tw-tracking-[2px]"
                            data-aos="fade-left"
                            data-aos-once="true"
                            data-aos-delay="400"
                        >
                            BROWSE ESTATES
                        </button>
                    </a>
                </div>
            </div>
            <div class="order-2 order-lg-1">
                <div
                    class="tw-relative mx-auto tw-w-full tw-h-[240px] lg:tw-h-[480px]"
                >
                    <div class="tw-relative w-100 h-100">
                        <div
                            class="slide-background-image overflow-hidden tw-w-full tw-h-full tw-bg-no-repeat tw-bg-cover tw-bg-center tw-relative"
                            v-bind:class="show"
                        >
                            <img
                                v-bind:class="hide"
                                :src="banner"
                                class="tw-object-cover w-100 h-100"
                            />
                        </div>
                        <!-- <div data-aos="curtainOutRight" data-aos-once="true" data-aos-offset="0" data-aos-duration="2000" data-aos-delay="0" class="tw-h-full tw-w-full tw-left-0 tw-top-0 tw-bottom-0 tw-absolute tw-bg-midGreen"></div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-relative">
            <div
                class="tw-absolute tw-w-full tw-h-[500px] tw-left-0 tw-bottom-[-300px] md:tw-bottom-[-400px] lg:tw-bottom-[-330px] xl:tw-h-[518px] mt-20px mt-0px-xl pl-15px pr-15px px-0px-xl lg:tw-p-0 tw-overflow-scroll lg:tw-overflow-auto"
            >
                <div
                    class="estates-overview__table tw-flex tw-w-[1000px] xl:tw-w-[85%] tw-h-[400px] xl:tw-h-[90%] tw-mx-auto tw-gap-x-4"
                >
                    <div
                        v-for="(estate, key) in estates"
                        class="tw-table-cell tw-bg-grey1 tw-relative even:tw-mt-[48px] even:tw-h-[448px] homepage-estate__thumbnail"
                        data-aos="fade-down"
                        :data-aos-delay="key * 300"
                        data-aos-once="true"
                        data-aos-duration="300"
                    >
                        <div class="tw-p-3 tw-text-left">
                            <p
                                class="tw-text-[20px] tw-text-darkGreen tw-text-base tw-font-bold m-0px"
                            >
                                {{ estate.estates?.name }}
                            </p>
                            <p
                                class="tw-text-[12px] tw-text-darkGreen2 tw-text-base tw-font-regular tw-uppercase tracking-wider m-0px"
                            >
                                {{ estate.estates?.location_id?.name }}
                            </p>
                        </div>
                        <div>
                            <img
                                :src="estate.thumbnail_link"
                                class="tw-w-[100%] estate-thumbnail"
                            />
                        </div>
                        <div
                            class="fs-14px lh-18px tw-p-3 tw-text-left tw-text-black tw-text-base tw-tracking-wider text-description-ellipsis"
                        >
                            {{
                                estate.estates?.long_description.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    ''
                                )
                            }}
                        </div>
                        <div
                            class="ls-2px tw-p-3 tw-absolute tw-bottom-0 tw-right-0"
                        >
                            <a
                                :href="this.sanitize(estate.estates?.url)"
                                class="tw-no-underline tw-text-brown tw-text-xs tw-tracking-[1px] tw-font-semibold hover:tw-text-brown"
                            >
                                <span>
                                    LEARN MORE<i
                                        aria-hidden="true"
                                        class="fa fa-chevron-right ml-10px fs-9px fs-10px-xl fw-bold"
                                    ></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.estates-overview__table {
    max-width: 1200px;
}
.estate-thumbnail {
    height: 150px;
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    width: 100%;
}
.homepage-estate__thumbnail {
    height: 410px;
    width: 240px;
    min-width: 240px;
}
.text-description-ellipsis {
    height: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

@media (min-width: 992px) {
    .homepage-estate__thumbnail {
        width: 200px;
        min-width: 200px;
    }
}

@media (min-width: 1400px) {
    .homepage-estate__thumbnail {
        width: 250px;
        min-width: 250px;
    }
}
</style>
